<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">快递业务</el-breadcrumb-item>
        <el-breadcrumb-item>月度对账单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="90px" :model="querys" ref="querys" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">搜索</el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add()">新增</el-button>
      <el-button type="danger" icon="delete" @click="dele()">删除</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler" :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:billDate="scope">
        <el-icon v-show="scope.row.billDate">
          <Calendar />
        </el-icon>
        <span style="margin-left: 10px">{{ (scope.row.billDate || "").split(" ")[0] }}</span>
      </template>
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" type="primary" link size="small">[编辑]</el-button>
        <el-button @click="watch(scope.row)" type="primary" link size="small">[查看]</el-button>
      </template>
    </static-table>

    <static-dialog ref="dialog" class="dialog" title="合作商信息维护" :ok="submit">
      <el-form :model="forms" :rules="rules" ref="forms" label-width="110px">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="合作商名称" prop="partnerID">
              <!-- <el-input v-model="forms.partnerName" autocomplete="off"></el-input> -->
              <el-select v-model="forms.partnerID" placeholder="请选择">
                <el-option v-for="item in dict.partners" :key="item.id" :label="item.partnerName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="联系人" prop="contactName">
              <el-input v-model="forms.contactName" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="联系方式" prop="contactPhone">
              <el-input v-model="forms.contactPhone" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="对账月份" prop="billDate">
              <el-date-picker v-model="forms.billDate" type="date" placeholder="" format="YYYY-MM-DD" style="max-width: 90%"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </static-dialog>

  </el-card>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { fetchMonthBill, fetchMonthBillList, delMonthBills, submitMonthBill,fetchPartnerList } from "@/api/mailscale";
export default {
  name: "PartnerList",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          { prop: "partnerName", label: "合作商名称", width: "180", sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "contactName", label: "联系人", width: "100", sortable: true, },

          { prop: "contactPhone", label: "联系方式", width: "135", searchable: true, },
          { type: "template", prop: "billDate", label: "对账月份", sortable: true, width: "120", },
          { prop: "remark", label: "备注", searchable: true },

          { type: "toolbar", label: "操作", width: "120" },
        ],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      forms: {},
      currRow: null,
      dict: {
        partners: []
      },

      rules: {
        partnerID: [
          { required: true, message: "请选择合作商信息", trigger: "blur" },
        ],
        billDate: [
          { required: true, message: "请输入账单月份", trigger: "blur" },
        ]
      },
    };
  },
  mounted() {
    var that = this;
    fetchPartnerList({columns:[{prop:'id',prop:'partnerName'}]}).then((res) => {
      that.dict.partners = res.data.data;
    });

    this.fetchs();
  },
  methods: {
    fetchs() {
      this.querys.filters = [];
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      fetchMonthBillList(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    add() {
      this.forms = {};

      // var that = this;
      // fetchPartnerList({columns:[{prop:'id',prop:'partnerName'}]}).then((res) => {
      //   that.dict.partners = res.data.data;
      // });

      this.$refs.dialog.toggle();
    },
    edit(row) {
      fetchMonthBill(row.id).then((res) => {
        this.forms = { ...res.data };
        this.$refs.dialog.toggle();
      });
    },
    watch(row) {
      this.$router.push("detail?id=" + row.id);
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;
        that.forms.billDate = this.$util.fmtDate(that.forms.billDate, "yyyy-MM-dd");
        that.forms.partnerName=this.dict.partners.filter(function(q){return q.id==that.forms.partnerID})[0].partnerName;
        submitMonthBill(that.forms).then((res) => {
          that.$message({ type: res.type, message: res.content, });

          if (res.data) {
            that.fetchs();
            that.$refs.dialog.toggle();
          }
        });
      });
    },
    dele() {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMonthBills(that.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },

    redirect() {
      this.$router.push("detail");
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    }
  },
};
</script>
<style lang="less">
</style>>

